































































import { Component, Vue } from "vue-property-decorator";
import { CBtn } from "@/models/utils/c-btn.interface";
import { TableHeader, USERS_HEADER } from "@/models/utils/c-table.interface";
import { Pagination } from "@/models/utils/pagination.interface";
import { users } from "@/services/api/users.service";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import TablesPaginationModule from "@/store/modules/tables_pagination.module";
import SearchModule from "@/store/modules/search.module";
import { UsersRequest } from "@/models/requests/users-request.interface";
import { UserWithExpertises } from "@/models/entities/user.interface";
const searchModule = getModule(SearchModule, store);
const tablesPaginationModule = getModule(TablesPaginationModule, store);

const CosmopolRefreshBtn = () =>
  import(
    /* webpackChunkName: "CosmopolRefreshBtn" */ "@/components/CosmopolRefreshBtn.vue"
  );
@Component({
  components: {
    "cosmopol-refresh-btn": CosmopolRefreshBtn,
  },
})
export default class Users extends Vue {
  private items: UserWithExpertises[] = [];
  private headers: TableHeader[] = USERS_HEADER;
  private isLoading = false;
  private serverItemsLength = 0;
  private source: AbortController = null;
  private homeBtn: Omit<CBtn, "type"> = {
    btnClass: "",
    label: "Home",
    labelClass: "c-label-dark",
    color: "var(--v-primary-lighten5)",
    icon: {
      name: "chevron-left",
      fill: "var(--v-primary-base)",
      height: 24,
      width: 24,
      iconStyle: "mt-2",
    },
  };
  private newUserBtn: Omit<CBtn, "type"> = {
    btnClass: "",
    label: "Nuovo utente",
    labelClass: "c-label-light",
    color: "var(--v-primary-base)",
    icon: {
      name: "plus",
      fill: "var(--v-primary-lighten4)",
      height: 30,
      width: 30,
      iconStyle: "mt-1",
    },
  };
  private pagination = null as Pagination;
  private onlyEnable = false;

  get vuexPagination(): Pagination {
    return tablesPaginationModule.users;
  }

  private async created(): Promise<void> {
    this.pagination = this.vuexPagination;
    this.$set(this.pagination, "search", searchModule.search);
    tablesPaginationModule.setUsersPagination(this.pagination);
    await this.loadTableItems();
  }

  private async loadTableItems(): Promise<void> {
    this.isLoading = true;
    this.source && this.source.abort();
    this.source = new AbortController();
    if (this.vuexPagination) this.pagination = this.vuexPagination;
    let r;
    const request: UsersRequest = {
      rows: this.pagination.rows,
      page: this.pagination.page,
      search: this.pagination.search,
      onlyEnable: this.onlyEnable,
    };
    r = await users.getAllUsers(request, this.source.signal);
    if (r.message !== "canceled") {
      this.items = r.data.items;
      this.serverItemsLength = r.data.totalRows;
      this.checkPagination(r.data.totalRows);
      this.isLoading = false;
    }
  }

  private checkPagination(totalRows: number): void {
    if (totalRows !== 0) {
      const lastPage = Math.ceil(totalRows / this.pagination.rows);
      this.pagination.page > lastPage &&
        this.$set(this.pagination, "page", lastPage);
    }
  }

  private updatePagination(): void {
    tablesPaginationModule.setUsersPagination(this.pagination);
    this.loadTableItems();
  }

  private goToUserForm(id: string | number): void {
    this.$router.push({ path: `user/${id}` });
  }

  private goHome(): void {
    this.$router.push({ path: "/" });
  }

  private onEnableClick(): void {
    this.onlyEnable = !this.onlyEnable;
    this.loadTableItems();
  }
}
