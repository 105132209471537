import { UserById, UserWithExpertises } from "@/models/entities/user.interface";
import { UsersRequest } from "@/models/requests/users-request.interface";
import { Paginated } from "@/models/utils/paginated.interface";
import { UserForm } from "@/models/utils/user-form.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "user";

class UsersService extends ApiService {
  getAllUsers(request: UsersRequest, signal: AbortSignal = null) {
    return this.getAll<Paginated<UserWithExpertises>>(CONTROLLER, {
      params: request,
      signal: signal,
    });
  }

  getAllUsersCombo() {
    return this.getAll<string[]>(`${CONTROLLER}/combo`);
  }

  getUserByUserId(userId: number) {
    return this.getByID<number, UserById>(`${CONTROLLER}`, userId);
  }

  saveUser(user: UserForm) {
    return this.post<UserForm, number>(CONTROLLER, user);
  }

  updateUser(
    userId: number,
    user: Omit<
      UserForm,
      "userName" | "userTypeId" | "expertiseId" | "employeeId"
    >
  ) {
    return this.updateById<
      number,
      Omit<UserForm, "userName" | "userTypeId" | "employeeId">,
      number
    >(CONTROLLER, userId, user);
  }
}

export const users = new UsersService();
